export const EVENT_NAMES = {
  COLLECTION_CLICKED: 'Collection Clicked',
  CLIP_CLICKED: 'Clip Clicked',
  VIEW_CATEGORY: 'Category Viewed',
  CLICK_CATEGORY: 'Category Clicked',
  ADD_TO_CART: 'Add to Cart',
  COLLECTION_ADD_TO_WISHLIST: 'Collection Added to Wishlist',
  CLIP_ADD_TO_WISHLIST: 'Clip Added to Wishlist',
  COLLECTION_PURCHASED_CREDITS: 'Collection purchased [credits]',
  CLIP_PURCHASED_CREDITS: 'Clip purchased [credits]',
  COLLECTION_PURCHASED_MONEY: 'Collection purchased [money]',
  COLLECTION_DOWNLOAD: 'Collection Download',
  CLIP_DOWNLOAD: 'Clip Download',
};

export const ATTRIBUTES = {
  CATEGORIES: 'categories',
};
