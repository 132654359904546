import { CollectionResponse } from '../types/api/CollectionsIndexTypes';
import { ProductResponse } from '../types/api/ProductsIndexTypes';

const getProductType = (product: CollectionResponse | ProductResponse): 'collection' | 'clip' => {
  if ((product as CollectionResponse).slug) return 'collection';
  return 'clip';
};

const productsHelpers = {
  getProductType,
};

export default productsHelpers;
