import { ENV } from '../constants/environments';
import { BROWSER_FINGERPRINT_COOKIE_NAME } from '../constants/utils';
import { EVENT_NAMES, ATTRIBUTES } from '../constants/algolia';

import { insightsClient, EventsItems, InsightsClient } from '@algolia/client-insights';
import { touchCookie } from './analytics/userInterestAnalytics';;
import productsHelpers from './products';
import getCookie from '../utils/getCookie';

import { User } from '../types/api/UsersTypes';
import { CategoryResponse } from '../types/api/CategoriesIndexTypes';
import { CollectionResponse } from '../types/api/CollectionsIndexTypes';
import { ProductResponseWithCollectionId } from '../types/api/ProductsIndexTypes';
import { AlgoliaEventProductType } from '../types/api/AlgoliaTypes';

const getTimestampAndFingerprint = (): { timestamp: number, browser_fingerprint: string } => {
  touchCookie();
  const timestamp = Date.now();
  const browser_fingerprint = getCookie(BROWSER_FINGERPRINT_COOKIE_NAME);
  return { timestamp, browser_fingerprint };
};

// Have to initialize client in each function to prevent tests from crashing
const initClient = (): InsightsClient => {
  const client = insightsClient(ENV.algoliaAppId, ENV.algoliaApiKey);
  return client;
};

const pushAddToCartEvent = async (
  collectionIds: Array<number>,
  user: User,
): Promise<void> => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'conversion',
          eventSubtype: 'addToCart',
          eventName: EVENT_NAMES.ADD_TO_CART,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          objectIDs: collectionIds.map(id => id.toString()),
        },
      ],
    });
  } catch {};
};

const pushAddToWishlistEvent = async (
  productIds: Array<number>,
  user: User,
  productType: AlgoliaEventProductType,
): Promise<void> => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  let eventName: string;
  switch (productType) {
    case 'collection':
      eventName = EVENT_NAMES.COLLECTION_ADD_TO_WISHLIST;
      break;
    case 'clip':
      eventName = EVENT_NAMES.CLIP_ADD_TO_WISHLIST;
      break;
    default:
      break;
  }
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'conversion',
          eventName,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          objectIDs: productIds.map(id => id.toString()),
        },
      ],
    });
  } catch {};
};

const pushCategoryViewEvent = async (
  user: User,
  category: CategoryResponse,
) => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'click',
          eventName: EVENT_NAMES.CLICK_CATEGORY,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          filters: [`${ATTRIBUTES.CATEGORIES}:${category.name}`],
        },
      ],
    });
  } catch {};
};

const pushCollectionClickEvent = async (user: User, collection: CollectionResponse) => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    const events: Array<EventsItems> = [
      {
        eventType: 'click',
        eventName: EVENT_NAMES.COLLECTION_CLICKED,
        index: ENV.algoliaCollectionsIndex,
        userToken: browser_fingerprint,
        authenticatedUserToken: user?.id?.toString(),
        timestamp,
        objectIDs: [collection.id.toString()],
      },
    ];
    if (collection.category)
      events.push({
        eventType: 'view',
        eventName: EVENT_NAMES.VIEW_CATEGORY,
        index: ENV.algoliaCollectionsIndex,
        userToken: browser_fingerprint,
        authenticatedUserToken: user?.id?.toString(),
        timestamp,
        filters: [`${ATTRIBUTES.CATEGORIES}:${collection.category.name}`],
      });
    await client.pushEvents({ events });
  } catch {};
};

const pushClipClickEvent = async (user: User, collectionId: number) => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    const events: Array<EventsItems> = [
      {
        eventType: 'click',
        eventName: EVENT_NAMES.CLIP_CLICKED,
        index: ENV.algoliaCollectionsIndex,
        userToken: browser_fingerprint,
        authenticatedUserToken: user?.id?.toString(),
        timestamp,
        objectIDs: [collectionId.toString()],
      },
    ];
    await client.pushEvents({ events });
  } catch {};
};

const pushCreditPurchaseEvent = async (
  user: User,
  items: Array<CollectionResponse | ProductResponseWithCollectionId>,
) => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  const { getProductType } = productsHelpers;
  const collections: Array<CollectionResponse> = items.filter(item => getProductType(item) === 'collection') as Array<CollectionResponse>;
  const clips: Array<ProductResponseWithCollectionId> = items.filter(item => getProductType(item) === 'clip') as Array<ProductResponseWithCollectionId>;
  const events: Array<EventsItems> = [];
  if (collections.length) {
    events.push({
      eventType: 'conversion',
      eventName: EVENT_NAMES.COLLECTION_PURCHASED_CREDITS,
      index: ENV.algoliaCollectionsIndex,
      userToken: browser_fingerprint,
      authenticatedUserToken: user?.id?.toString(),
      timestamp,
      objectIDs: collections.map(collection => collection.id.toString()),
    });
  };
  if (clips.length) {
    events.push({
      eventType: 'conversion',
      eventName: EVENT_NAMES.CLIP_PURCHASED_CREDITS,
      index: ENV.algoliaCollectionsIndex,
      userToken: browser_fingerprint,
      authenticatedUserToken: user?.id?.toString(),
      timestamp,
      objectIDs: clips.map(clip => clip.collection_id.toString()),
    });
  };
  try {
    await client.pushEvents({ events });
  } catch {};
};

const pushDownloadEvent = async (
  user: User,
  productIds: Array<number>,
  productType: AlgoliaEventProductType,
) => {
  const client = initClient();
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  let eventName: string;
  switch (productType) {
    case 'clip':
      eventName = EVENT_NAMES.CLIP_DOWNLOAD;
      break;
    case 'collection':
      eventName = EVENT_NAMES.COLLECTION_DOWNLOAD;
      break;
    default:
      break;
  };
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'conversion',
          eventName,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          objectIDs: productIds.map(id => id.toString()),
        },
      ],
    });
  } catch {};
};

const algoliaHelpers = {
  pushAddToCartEvent,
  pushCategoryViewEvent,
  pushCollectionClickEvent,
  pushAddToWishlistEvent,
  pushCreditPurchaseEvent,
  pushDownloadEvent,
  pushClipClickEvent,
};

export default algoliaHelpers;
