import { CartOrderState } from '../../types/state/reducers/cartReducersTypes';
import { OrderCreateRequest } from '../../types/api/OrderTypes';
import { User } from '../../types/api/UsersTypes';

import { initialCartOrderState } from '../reducers/cart';
import { CART_ACTIONS } from '../../constants/actions';
import { addToCartEvent } from './analyticsActions';

import cartHelpers from '../../helpers/cart';
import algoliaHelpers from '../../helpers/algolia';

import { OrderClient } from '../../shared/libs/OrderHandler';

export const openCartAction = () => ({
  type: CART_ACTIONS.OPEN_CART
});

export const closeCartAction = () => ({
  type: CART_ACTIONS.CLOSE_CART
});

export const setLoading = () => ({
  type: CART_ACTIONS.SET_LOADING
});

export const clearLoading = () => ({
  type: CART_ACTIONS.CLEAR_LOADING
});

export const updateOrderAction = (payload: CartOrderState) => {
  return {
    type: CART_ACTIONS.UPDATE_ORDER,
    payload
  };
};

const orderController = new OrderClient();

export const setInitialState = (): void => {
  const { licenseId, discount, products } = initialCartOrderState;
  orderController.createOrder({
    licenseId: licenseId,
    discount: discount.code,
    products: Object.keys(products).map((num) => Number(num))
  });
};

export const initialOrderThunk = (): any => () => {
  return orderController.getOrder();
};

export const addProductThunk = (
  state: CartOrderState,
  variantId: number,
  collectionId: number,
  licenseId: number = null,
  user: User,
  doNotOpenCart?: boolean,
  callback?: () => void,
): any => {
  const { pushAddToCartEvent } = algoliaHelpers;
  let currentProducts: number[] = Object.keys(state.products).filter(key => cartHelpers.filterOutDiscountItem(state.products[key])).map((num) => Number(num));
  currentProducts.push(variantId);

  let order = {
    product_ids: currentProducts,
    license: licenseId || state.licenseId
  };

  return (dispatch) => {
    dispatch(setLoading());
    orderController.addItem(order, async () => {
      dispatch(clearLoading());
      if (callback) callback();
      await pushAddToCartEvent([collectionId], user);
    });
    if (!doNotOpenCart) dispatch(openCartAction());
    dispatch(addToCartEvent(variantId));
  };
};

export const removeProductThunk = (state: CartOrderState, productId: number): any => {
  let products = state.products;
  delete products[String(productId)];
  const reqBody = {
    licenseId: state.licenseId,
    discount: state.discount.code,
    products: Object.keys(products).filter(key => cartHelpers.filterOutDiscountItem(products[key])).map((num) => Number(num))
  };
  return () => orderController.removeItem(reqBody as any);
};

export const clearCartThunk = (state: CartOrderState): any => {
  const reqBody = {
    licenseId: null,
    discount: state.discount.code,
    products: []
  };
  return () => orderController.removeItem(reqBody as any);
};

export const changeLicenseThunk = (state: CartOrderState, licenseType: string): any => {
  const reqBody: OrderCreateRequest = {
    licenseId: parseInt(licenseType),
    discount: state.discount.code,
    products: Object.keys(state.products).filter(key => cartHelpers.filterOutDiscountItem(state.products[key])).map((num) => Number(num))
  };
  return () => orderController.changeLicense(reqBody);
};

export const changeDiscountThunk = (state: CartOrderState, discountCode: string, successCb?: Function, errorCb?: Function): any => {
  let reqBody = {
    discount: discountCode,
    // license: state.licenseType,
    products: Object.keys(state.products).map((num) => Number(num))
  };
  return () => orderController.addDiscount(reqBody as any, successCb, errorCb);
};
