import { ENV } from '../../../constants/environments';

import axios from 'axios';
import algoliaHelpers from '../../../helpers/algolia';

import { User } from '../../../types/api/UsersTypes';
import { ProductVariantPrice } from '../../../types/state/reducers/productVariantsPricesTypes';
import { InfoTableProps } from '../../../types/pages/collections/CollectionTypes';
import { LicenseTypeDetail, LicenseDetail } from '../../../types/api/LicenseTypesTypes';
import { ProductDownloadResponse } from '../../../types/api/DownloadsTypes';
import { GenericResponse } from '../../../types/api/Http';

export function getCollectionLinkHref(slug, library) {
  switch (library.id) {
    case 1:
      return `/collections/[slug]`;
    case 2:
      return `/practice-footage/[slug]`;
    case 3:
      return `/3d/[slug]`;
    default:
      return `/collections/[slug]`;
  }
}

export function getCollectionPath(slug, library, sku = null) {
  // Fallback based on SKU
  if (library == null && sku != null) {
    if (sku.includes('3D')) {
      return `/3d/${slug}`;
    }
    if (sku.includes('PFL')) {
      return `/practice-footage/${slug}`;
    }
    if (sku.includes('PFL')) {
      return `/collections/${slug}`;
    }
  }

  switch (library?.id) {
    case 1:
      return `/collections/${slug}`;
    case 2:
      return `/practice-footage/${slug}`;
    case 3:
      return `/3d/${slug}`;
    default:
      return `/collections/${slug}`;
  }
}

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index && value != null && value != '';
};

export const fixOptionTitle = (title) => {
  const fixes = {
    effectType: 'Effect Type',
    cameraMovement: 'Camera Movement',
    recordedFps: 'Recorded FPS',
    frameRate: 'Frame Rate',
    colorSpace: 'Color Space',
    fps: 'FPS',
    fpsRecorded: 'FPS',
    fpsTimebase: 'FPS',
    alphaChannel: 'Alpha Channel',
    alpha: 'Alpha Channel',
    modelRelease: 'Model Release',
    assetType: 'Asset Type',
    pbrTextures: 'PBR Textures',
    uvMapped: 'UV Mapped',
    rigged: 'Rigged',
    photoScanned: 'Photoscanned',
    textureResolution: 'Texture Resolution',
    baseColor: 'Base Color',
    ambientOcclusion: 'Ambient Occlusion',
    vdbChannels: 'VDB Channels',
    'Auto Formats': 'Formats'
  };
  if (fixes[title]) return fixes[title];

  return title;
};

/**
 * When we can't get the variant label from the Resolution spec, fall back to using
 * using the "label" attribute
 */
export const fallbackVariantLabel = (variant) => {
  return variant.product_attributes.filter((a) => a.key == 'Label')[0]?.value || 'All';
};

export const optionablesToSpecs = (variantsArray): object => {
  let specs = {};

  const optionables = variantsArray
    .sort((v1, v2) => v1.price - v2.price)
    .map((p) => p.optionables.sort((v1, v2) => v1.position - v2.position));

  for (const p of optionables) {
    for (const spec of p) {
      if (specs[spec.full_type] == undefined) {
        specs[spec.full_type] = [];
      }
      let specValue = specs[spec.full_type].find((v) => v.value == fixOptionValue(spec));
      if (specValue == undefined) {
        specValue = { value: fixOptionValue(spec), disclaimers: [] };
        specs[spec.full_type].push(specValue);
      }

      specValue.value = fixOptionValue(spec);
      specValue.disclaimers.push(spec.disclaimer);
      specValue.disclaimers = specValue.disclaimers.filter(onlyUnique);
    }
  }

  // Cleanup disclaimers - if they all match, there's no need for them.
  for (let spec of Object.values(specs)) {
    const specsMatch = (spec as any)
      .map((s) => s.disclaimers.join(','))
      .every((val, i, arr) => val === arr[0]);
    if (specsMatch) {
      for (let value of spec as any) {
        value.disclaimers = [];
      }
    }
  }
  return specs;
};

export const fixOptionValue = (spec): string => {
  if (typeof spec.value === 'string' && spec.value.toLowerCase().trim() == '[custom_value]') {
    spec.value = spec.disclaimer;
    spec.disclaimer = '';
    return spec.value;
  }

  if ((spec.type == 'alpha' || spec.type == 'alphaChannel') && typeof spec.value == 'boolean') {
    return spec.value ? 'Included' : 'Not Included';
  }

  if (
    (spec.type == 'alpha' || spec.type == 'alphaChannel') &&
    (spec.value == 'true' || spec.value == 'false')
  ) {
    return spec.value ? 'Included' : 'Not Included';
  }

  if (spec.type == 'resolution') return spec.base_value;

  if (spec.value == 'Maya') {
    return '<div class="flex items-center gap-1"><img src="/img/maya.png" style="width:12px;" class="inline" /> Maya</div>';
  }
  if (spec.value == 'Blender') {
    return '<div class="flex items-center gap-1"><img src="/img/blender.png" style="width:16px;" class="inline" /> Blender</div>';
  }
  return spec.value;
};

function checkDownloadable(id) {
  return true;
  // try {
  //   let downloads = JSON.parse(localStorage.getItem('download_attempts')) || {};

  //   // Dedupe downloads for an 10 minutes
  //   if (downloads[id] && downloads[id] < Date.now() - 600) {
  //     return false;
  //   }

  //   downloads[id] = Date.now();
  //   localStorage.setItem('download_attempts', JSON.stringify(downloads));
  //   return true;
  // } catch (e) {
  //   return true;
  // }
}

export async function downloadProduct(id: number, user: User, callback: Function, collectionId: number) {
  if (!checkDownloadable(id)) {
    return false;
  }

  try {
    const res: GenericResponse<ProductDownloadResponse> = await axios.get(`${ENV.api.baseURL}/variant_downloads/${id}/download`, {
      headers: {
        authorization: user.token,
      }
    });
    const shouldCheckDownloadLimit = !!res.data.download_limit || res.data.download_limit === 0;
    if (shouldCheckDownloadLimit && res.data.downloads_today > res.data.download_limit) {
      callback(res);
      return true;
    }
    var link = document.createElement('a');
    link.href = res.data.url;
    link.download = 'file';
    link.click();
    callback(res);
    await algoliaHelpers.pushDownloadEvent(user, [collectionId], 'clip');
    return true;
  } catch (e) {
    callback(e.response);
    return e.response.status;
  }
}

export async function downloadPflProduct(id: number, user: User, callback: Function, collectionId: number) {
  if (!checkDownloadable(id)) {
    return false;
  }

  try {
    const res: GenericResponse<ProductDownloadResponse> = await axios.post(
      `${ENV.api.baseURL}/variant_downloads/${id}/scene`,
      {},
      {
        headers: {
          authorization: user.token
        }
      }
    );
    const shouldCheckDownloadLimit = !!res.data.download_limit || res.data.download_limit === 0;
    if (shouldCheckDownloadLimit && res.data.downloads_today > res.data.download_limit) {
      callback(res);
      return true;
    }
    var link = document.createElement('a');
    link.href = res.data.url;
    link.download = 'file';
    link.click();
    callback(res);
    await algoliaHelpers.pushDownloadEvent(user, [collectionId], 'clip');
    return true;
  } catch (e) {
    callback(e.response);
    return e.response.status;
  }
}

export async function downloadCollection(id: number, user: User, collectionId: number) {
  if (!checkDownloadable(id)) {
    return false;
  }

  const res = await axios.post(
    `${ENV.api.baseURL}/variant_downloads/${id}/download_collection_variant`,
    {},
    {
      headers: {
        authorization: user.token
      }
    }
  );
  window.location.href = res.data;
  await algoliaHelpers.pushDownloadEvent(user, [collectionId], 'collection');
}

export function prettyFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const rounded = (size / Math.pow(1024, i)).toFixed(1);
  return rounded + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function getVideoSources(product: any): Array<any> {
  const videos = product.video.sources?.filter((s) => s.size == 'original') || [];
  const fallbackUrl = typeof product.video === 'string' ? product.video : (product.video?.link || product.media?.mp4);
  const fallback = [{
    src: fallbackUrl,
    size: 'original',
    type: 'video/mp4'
  }];
  return videos.length > 0 ? videos : fallback;
}

export function getClipPreviewSources(product: any): Array<any> {
  const videos = product.video.sources.filter((s) => s.size == 'medium');
  const fallback = [{
    src: product.video.link || product.media?.mp4,
    size: 'medium',
    type: 'video/mp4'
  }];
  return videos.length > 0 ? videos : fallback;
}

export function buildPrice(price: number, originalPrice: number): React.ReactNode {
  let elems: React.ReactNode[] = [];

  if (price !== originalPrice) {
    elems = elems.concat([
      <del className="price del" key="del-price" data-testid="priceDel">
        ${originalPrice.toFixed(2)}
      </del>,
      <ins className="price ins" key="ins-price" data-testid="priceIns">
        ${price.toFixed(2)}
      </ins>
    ]);
  } else {
    elems.push(
      <span className="price" key="full-price" data-testid="price">
        ${originalPrice.toFixed(2)}
      </span>
    );
  }

  return (
    <div className="price-container" data-testid="priceContainer">
      {elems}
    </div>
  );
}

export function buildOptionables(variants): InfoTableProps {
  const result: any = {};
  result['resolution'] = [];
  result['format'] = [];
  const loopedSpecs = ['resolution', 'format'];

  variants.forEach((variant) => {
    variant.optionables.reduce((res, optionable) => {
      let key = optionable.type;
      let value = optionable.value;
      if (loopedSpecs.includes(key)) {
        if (key == 'format' && result['format'] && !result['format'].includes(value)) {
          result['format'] = result['format'].concat(value);
        } else if (key == 'resolution' && result['format']) {
          result['resolution'] = result['resolution'].concat({
            value: value,
            width: optionable.width,
            height: optionable.height
          });
        }
      } else {
        result[key] = value;
      }
    }, result);
  });

  return Object.assign(
    {},
    { format: result.format },
    { fps: result.fps },
    { shotWith: result.camera },
    { alphaChannel: result.alpha },
    { resolution: result.resolution },
    { fpsRecorded: result.fpsRecorded }
  );
}

export function findVariantPrice(
  variant: any,
  variantPrices: Array<ProductVariantPrice> = [],
  format:
    | 'default'
    | 'credit'
    | 'upgradeCredit'
    | 'original'
    | 'default' = 'default'
): string | number | undefined {
  const variantPricesObject = variantPrices.find(
    (variantPrice: ProductVariantPrice) => variantPrice.id === variant.id
  );
  if (!variantPricesObject) return;
  switch (format) {
    case 'credit':
      return variantPricesObject.credit_price;
    case 'upgradeCredit':
      return variantPricesObject.upgrade_credit_price;
    case 'original':
      return variantPricesObject.original_price;
    case 'default':
    default:
      return variantPricesObject.price;
  }
}

export function findProductPriceVariantsIndexInArray(
  variantsArray: Array<Array<ProductVariantPrice>>,
  variantsPricesObjectToFind: Array<ProductVariantPrice> | Array<{ id: number }>
): number {
  if (!variantsArray || !variantsPricesObjectToFind) return -1;
  for (let i = 0; i < variantsArray.length; i++) {
    const variantPrices: Array<ProductVariantPrice> = variantsArray[i];
    if (
      variantPrices.every((vp: ProductVariantPrice) =>
        variantsPricesObjectToFind.find((vpToFind: ProductVariantPrice) => vpToFind.id === vp.id)
      )
    )
      return i;
  }
  return -1;
}

export function getModifiedPrice(
  price: number,
  selectedLicenseType: LicenseTypeDetail,
  selectedLicense: LicenseDetail,
  isSubscription: boolean = false
): number {
  const priceFactorFieldName = isSubscription ? 'subscription_price_factor' : 'price_factor';
  let price_factor = 1;

  if (selectedLicense) price_factor = selectedLicense[priceFactorFieldName] as number;
  else if (selectedLicenseType?.id === 3)
    price_factor = selectedLicenseType.licenses[0][priceFactorFieldName] as number;

  if (typeof price_factor === 'string') price_factor = parseFloat(price_factor);

  return price * price_factor;
}

const collectionsHelpers = {
  prettyFileSize,
  downloadCollection
};

export default collectionsHelpers;
